@import '../../styles/variables';

.variant {
  composes: variant from '../../styles/variants.module.scss';
}

:global(.ecom-flow-revamp) .variant {
  border-top: 8px #f3f3f2 solid;
  padding: 32px 24px 32px 24px;
}

.variantTitleGroup {
  composes: variantTitleGroup from '../../styles/variants.module.scss';
}

.variantTitleGroupTest {
  margin-bottom: 6px !important;
}

.variantTitle {
  composes: variantTitle from '../../styles/variants.module.scss';
}

.variantSubtitle {
  composes: variantSubtitle from '../../styles/variants.module.scss';
}

.variantTitleError {
  composes: variantTitleError from '../../styles/variants.module.scss';
}

.variantInfo {
  composes: variantInfo from '../../styles/variants.module.scss';
}

.moreInfo {
  composes: moreInfo from '../../styles/variants.module.scss';
}

.variantSubtitle {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: black;
}

.noSubscriptionOption {
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 600;
}

.disabledSection {
  color: #999;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}

.giftFiSubscriptionOptionButton {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  &Price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    font-family: $font-default;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 4px;
  }

  &Details {
    font-family: $font-default;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: black;
  }

  &Discount {
    border-radius: 14px;
    background-color: #e0e0e0;
    display: flex;
    padding: 4px 10px;
    align-items: flex-end;
    gap: 10px;
    border-radius: 14px;

    text-align: right;
    font-family: $font-default;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

.ollieWelcomeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  background-color: #f3f3f2;
  padding: 16px;
  width: calc(100% - 32px);
  margin-bottom: 8px;
}

.ollieWelcomeBoxImage {
  border-radius: 8px;
  height: 78px;
  margin-right: 16px;
}

.ollieWelcomeBoxContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ollieWelcomeBoxTitle {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.ollieWelcomeBoxDescription {
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #3c3c3c;
}
