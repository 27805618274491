.main {
  display: flex;
  flex-direction: column;
  gap: 0.1em;
}

.productGroup {
  display: flex;
  flex-direction: column;
  gap: 0.1em;
}
