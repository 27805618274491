@import '../../../../../styles/variables';

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 2em;
  margin-bottom: 3em;
}

.addToCartContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 3;
  opacity: 1;
  transition: all 0.3s ease;
}

.addToCartContainer:has(.descriptionText) {
  background: #ffffff;
  z-index: 3;
}

.addToCartButton button {
  width: 100%;
}

.descriptionText {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.jumpButton {
  visibility: hidden;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  padding: 12px 24px;
  width: max-content;
  max-width: 90%;
  border-radius: 100px;
  background: var(--Background-Dark-Primary, #000);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.08);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.3s ease, visibility 0s linear 0.3s; // Delay hiding visibility to match opacity transition
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;

  &.visible {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.3s ease, visibility 0s linear;
  }
}

.fixedCTA {
  @media (max-width: $lg-min-width) {
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 16px;
    background: white;
    z-index: 1000;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.12);
    transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.3s ease, visibility 0s linear 1s;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0); // Slide into view
    pointer-events: auto;
    transition-delay: 0.5s, 0.5s, 1s; // delay transform & opacity, 1s on visibility
  }
}

.inlineCTA {
  @media (max-width: $lg-min-width) {
    position: static;
  }
}

.fixedCTA,
.inlineCTA {
  @media (max-width: $lg-min-width) {
    transition: all 0.3s ease-in-out;
  }
}
