@import '../../../../../styles/variables';

.productInfo {
  display: flex;
  flex-direction: column;
  padding: 80px 24px;
  align-items: center;
}

.imageGalleryContainer {
  composes: mdFullWidth from '../../../../../styles/container.module.scss';
}

.productInfo :global(.flickity-page-dots) {
  display: flex;
  position: absolute;
  bottom: 16px;
  background-color: unset;
  justify-content: center;
}

.productInfo :global(.flickity-slider) picture {
  width: 100%;
}

.desktopImageGallery {
  display: none;

  @media (min-width: $lg-min-width) {
    display: block;
  }
}

.mobileImageGallery {
  display: block;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}

.titleText {
  margin: 52px 0px 0px 0px;
  padding: 0px;
  align-self: flex-start;

  font-family: $font-title;
  font-size: 48px;
  font-weight: 700;
  line-height: 105%;
  letter-spacing: -0.02em;

  // account for image gallery margins
  @media (max-width: $md-max-width) {
    margin: calc(1em + 52px) 0px 0px 0px;
  }
}

.descriptionText {
  margin: 12px 0px 0px 0px;
  padding: 0px;
  align-self: flex-start;

  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.skuOptionSelector {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
}

.skuRecommended {
  padding: 12px 25.5px;
  width: calc(100% - 55px);
  border-radius: 24px 24px 0px 0px;
  background-color: #fffa01;
  border: 2px solid #bebebb;
  border-bottom: none;
  margin-top: 8px;

  font-family: $font-default;
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.24px;
}

.skuRecommendedSelected {
  border: 2px solid black;
  border-bottom: none;
}

.skuOption {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border: 2px solid #bebebb;
  border-radius: 16px;
  margin: 8px 0;
  cursor: pointer;

  &.insights {
    border-radius: 0px 0px 16px 16px;
    margin-top: 0;
  }
}

.skuOptionSelected {
  border: 2px solid #242424;
}

.skuOptionImage {
  width: 60px;
  flex-shrink: 0;
}

.skuOptionContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.skuOptionText {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}

.skuOptionTitle {
  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.skuOptionDescription {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #737373;
}

.skuOptionKeyBenefitExpandButton,
.skuOptionKeyBenefitCollapseButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-top: 16px;
  padding-left: 16px;
  border-top: 1px solid #e5e5e4;
  margin-top: 16px;

  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.01em;

  svg {
    width: 12px;
    height: 12px;
  }
}

.skuOptionKeyBenefitCollapseButton {
  margin-bottom: 24px;
}

.skuOptionKeyBenefitContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.skuOptionKeyBenefitItem {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.skuOptionKeyBenefitItemImageContainer,
.skuOptionKeyBenefitItemImageContainer svg {
  width: 24px;
  height: 24px;
  align-self: flex-start;
}

.skuOptionKeyBenefitItemText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.skuOptionKeyBenefitItemTitle {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -0.01em;
}

.skuOptionKeyBenefitItemDescription {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  color: #737373;
}

.benefitContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media (min-width: $lg-min-width) {
    margin-bottom: 60px;
  }
}

.benefitContainerSubtitle {
  margin-top: 20px;
  font-family: $font-default;
  font-size: 12px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0.24px;
  text-transform: uppercase;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}

.benefitContainerTitle {
  margin-top: 4px;
  margin-bottom: 28px;
  font-family: $font-default;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.96px;

  @media (min-width: $lg-min-width) {
    margin-top: 60px;
    text-align: center;
    font-family: $font-title;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.8px;
  }
}

.benefitItemContainer {
  display: flex;
  flex-direction: column;

  @media (min-width: $lg-min-width) {
    max-width: 526px;
    align-self: center;
  }
}

.benefitItem {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  margin-top: 4px;
}

.benefitImage,
.benefitImage svg {
  width: 24px;
  height: 24px;
  align-self: flex-start;
}

.benefitTitle {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;
}

.benefitDescription {
  margin-top: 4px;
  margin-bottom: 12px;
  font-family: $font-default;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.14px;
  color: #737373;
}

.ingredientsTitle {
  margin-top: 20px;
  margin-bottom: 16px;
  font-family: $font-default;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.96px;

  @media (min-width: $lg-min-width) {
    margin-top: 32px;
    text-align: center;
    font-family: $font-title;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.8px;
  }
}

.ingredients {
  margin-bottom: 16px;
  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #737373;

  @media (min-width: $lg-min-width) {
    max-width: 814px;
  }
}

.ingredientsBold {
  font-weight: 600;
}

.ingredientsDisclaimer {
  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #737373;

  @media (min-width: $lg-min-width) {
    max-width: 814px;
  }
}

.reviewsContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  width: 100%;

  @media (min-width: $lg-min-width) {
    max-width: 814px;
  }
}

.reviewsTitle {
  font-family: $font-default;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.96px;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}

.reviewsTitleDesktop {
  display: none;

  @media (min-width: $lg-min-width) {
    display: block;
    text-align: center;
    font-family: $font-title;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.8px;
  }
}

.reviewRating {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 10px;
  align-items: center;
}

.heroReviewItem .reviewRating {
  gap: 18px;

  @media (min-width: $lg-min-width) {
    justify-self: center;
    margin-top: 40px;
  }
}

.reviewRating svg * {
  fill: #ffcb11;
}

.reviewRating svg {
  transform: scale(1.37);
  transform-origin: left top;
  width: auto;
  fill: #ffcb11;
}

.heroReviewItem .reviewRating svg {
  transform: scale(1.82);
  transform-origin: left top;
  width: auto;
  fill: #ffcb11;
}

.heroReviewItem .reviewText {
  margin-top: 20px;
  margin-bottom: 8px;
  font-family: $font-default;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.56px;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}

.heroReviewItem .reviewTextDesktop {
  display: none;

  @media (min-width: $lg-min-width) {
    margin-top: 30px;
    display: block;
    text-align: center;
    font-family: $font-default;
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.96px;
  }
}

.heroReviewItem {
  padding-bottom: 24px;
  border-bottom: 1px solid #f3f3f2;

  @media (min-width: $lg-min-width) {
    border-bottom: none;
  }
}

.smallReviewContainer {
  @media (min-width: $lg-min-width) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    max-width: 814px;
    gap: 50px;

    .reviewItem {
      width: 33%;
    }
  }
}

.reviewText {
  margin-top: 16px;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;

  @media (min-width: $lg-min-width) {
    font-weight: 500;
  }
}

.reviewTextBold {
  font-weight: 700;

  @media (min-width: $lg-min-width) {
    font-weight: 600;
  }
}

.divider {
  height: 8px;
  margin: 0px -24px;
  width: calc(100% + 48px);
  background-color: #f3f3f2;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}

.purchaseContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.purchaseTitle {
  margin-top: 32px;
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.48px;
}

.purchaseOptionContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  gap: 16px;
  width: 100%;
}

.autoshipPurchaseOption {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  border-radius: 24px;
  border: 2px solid #f1f1ef;
  cursor: pointer;
}

.buyOncepurchaseOption {
  display: flex;
  padding: 16px 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 24px;
  border: 2px solid #f1f1ef;
  cursor: pointer;
}

.purchaseOptionSelected {
  border: 2px solid black;
}

.purchaseOptionAutoshipUpsell {
  padding: 12px 25.5px;
  width: calc(100% - 51px);
  border-radius: 24px 24px 0px 0px;
  border-bottom: 2px solid #f1f1ef;
  background-color: #fffa01;

  font-family: $font-default;
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.24px;
}

.purchaseOptionSelected .purchaseOptionAutoshipUpsell {
  border-bottom: 2px solid black;
}

.autoshipPurchaseOptionContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 30px;
  border-radius: 0px 0px 24px 24px;
  width: calc(100% - 60px);
}

.purchaseOptionTitle {
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.48px;
}

.purchaseOptionTitleAutoship {
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.48px;
}

.purchaseOptionDescription {
  font-family: $font-default;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.originalPurchaseOptionPrice {
  text-decoration: line-through;
  font-family: $font-default;
  color: $type-light-secondary;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.purchaseOptionPrice {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.frequencyTitle {
  margin-bottom: 16px;
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.48px;
}

.frequencyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  padding: 19px 16px;
  border-radius: 86px;
  border: 1px solid #bebebb;
}

.frequencyMinusButton,
.frequencyPlusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border: none;
  position: relative;
}

.frequencyMinusButton::before,
.frequencyPlusButton::before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.frequencyMinusButtonDisabled,
.frequencyPlusButtonDisabled {
  opacity: 0.25;
  pointer-events: none;
}

.frequencyValue {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.frequencyValueUnit {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
}

.whatIsRightContainer {
  display: flex;
  padding: 16px 20px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 16px;
  background: #f3f3f2;
  cursor: pointer;
}

.whatIsRightTitle {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.14px;
}

.freeGroundShippingContainer,
.tryRiskFreeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
}

.tryRiskFreeContainer {
  margin-bottom: 44px;
}

.freeGroundShippingIcon,
.tryRiskFreeIcon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: #f3f3f2;
}

.freeGroundShippingTitle,
.tryRiskFreeTitle {
  font-family: $font-default;
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
}

.addToCartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  padding: 20px 16px;
  background-color: white;
  width: calc(100% - 32px);
  border-top: 0.33px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.12);
}

.addToCartButton {
  width: 100%;
  padding: 18px 0px;
  margin: 0px 16px;
  border-radius: 100px;
  background: black;
  color: white;

  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.16px;
  cursor: pointer;
  border: none;
}

.autoshipPricing {
  margin-top: 16px;
  font-family: $font-default;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.recommendedInsightFrequency {
  font-weight: 600;
}
