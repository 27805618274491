@import '../../styles/variables';

@keyframes slide-up {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
 * Need to use !important to override inline styles that reactjs-popup adds.
 * Using global styles here because their className prop appends to the CSS module hashed class names.
 */
:global(.s3-popup-content) {
  background-color: #ffffff;
  padding: 0 !important;
  border-radius: 1.5em;
  box-sizing: border-box;
  width: auto !important;
  max-width: 95%;
  max-height: 95vh;
  z-index: 105;

  @media (min-width: $md-min-width) {
    max-width: $sm-max-width;
  }

  @media (max-width: $sm-max-width) {
    border-radius: 1.5em 1.5em 0 0;
    width: 100% !important;
    max-width: 100%;
    position: absolute !important;
    margin: 0 !important;
    bottom: 0 !important;
    animation: slide-up 0.15s ease-in-out 0s 1 forwards;
  }
}

:global(.s3-popup-overlay) {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7px);
  z-index: 104;
  animation: fade-in 0.2s linear 0s 1 forwards;
}

.container {
  font-family: $font-default;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  max-width: inherit;
  max-height: inherit;
  display: flex;
  flex-direction: column;
}

.body {
  line-height: 1.4em;
  padding: 5em 3.5em;
  box-sizing: border-box;
  overflow-y: auto;
  flex-grow: 1;

  p {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }

  h1,
  h2,
  h3 {
    font-family: $font-title;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.25em;
    line-height: 1em;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-family: $font-default;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    letter-spacing: -0.4px;
  }

  hr {
    border: 0.1px solid $gray-100;
    margin: 10px -20px;
  }
}

.actions {
  margin: 0;
  &.shrink {
    margin-bottom: 2em;
  }

  .button {
    width: 90%;
    margin: auto;
    font-weight: 600;
    &.loading {
      height: 4em;
      padding: inherit;
      .content {
        display: none;
      }

      &::before {
        background-image: url('../../assets/images/spinner_loader.gif');
        content: '';
        width: 16px;
        height: 16px;
        background-size: 16px;
        display: inline-block;
      }
    }

    &.shorterButton {
      height: 55px;
    }

    &.tertiaryButton {
      color: black;
      margin-top: 3px;
    }
  }
}

.closeIcon {
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
  margin: 1.5em 1.5em 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
  h3 {
    margin-bottom: 0;
  }
  svg {
    margin: auto;
    margin-right: 0;
  }
}

.highlight {
  background-color: rgba(7, 161, 87, 0.12);
  padding: 25px;
  margin-bottom: 1.5em;

  .title {
    color: $green-400;
    font-size: 20px;
    font-weight: 700;
  }
  .subtitle {
    font-size: 13px;
    font-weight: 500;
  }
}

@media (max-width: $sm-max-width) {
  .body {
    padding: 2em 1.5em 0em;

    h1 {
      font-size: 3rem;
    }
  }

  .container:has(.closeIcon) {
    .body {
      padding-top: 0;
    }
  }

  .shrink {
    padding-bottom: 0;
    padding-top: 2em;
  }

  .loading {
    padding: 0;
    margin: auto;
    height: 30px;
    width: 30px;
    img {
      height: 30px;
      width: 30px;
      width: auto;
    }
  }
}
