@import '../../styles/variables';

.fullWidthWrapper {
  background-color: transparent;
  overflow: scroll;
  z-index: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 10em;
}

.container {
  composes: container from '../../styles/container.module.scss';

  .mainHeader {
    font-family: $font-default;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .sectionHeaderContainer {
    margin-bottom: 20px;
  }

  .sectionHeader {
    font-family: $font-default;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .sectionSubheader {
    font-family: $font-default;
    font-size: 26px;
    font-weight: 400;
    color: #737373;
    margin: 0;
  }

  .currentPlanContainer {
    display: flex;
    background-color: #f5f5f2;
    border-radius: 1.5em;
    height: 86px;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 21px;
    margin-bottom: 28px;
  }

  .currentPlanUnit {
    font-family: $font-default;
    font-size: 12px;
    line-height: 140%; /* 16.8px */
    letter-spacing: -0.12px;
    font-weight: 700;
  }

  .currentPlanRate {
    font-family: $font-default;
    font-size: 24px;
    font-weight: 600;
  }

  .productAction {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }

  .stickyCTA {
    background: #ffffff;
    position: fixed;
    padding-bottom: 25px;
    box-sizing: border-box;
    .button {
      width: 90%;
    }
    .selectedPlanDescription {
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .stickyCTA:has(.selectedPlanDescription) {
    border-top: 0.33px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.12);
  }
}

.simpleFullWidthWrapper {
  background-color: transparent;
  overflow: scroll;
  z-index: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.simpleContainer {
  composes: container from '../../styles/container.module.scss';

  height: 100%;
  padding: 0px;
  gap: 16px;

  .simpleSpacer {
    height: 30%;
  }

  .specialOfferChip {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    p {
      padding: 4px 10px;
      border-radius: 100px;
      border: 2px solid white;
      background: $green-500;
      margin: 0;

      font-family: $font-default;
      font-size: 14px;
      font-weight: 700;
      color: $type-dark-primary;
    }
  }

  .simpleHeaderContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 4px 16px 8px 16px;

    h1,
    p {
      margin: 0;
      text-align: center;
      color: $type-light-primary;
    }

    .simpleHeader {
      font-family: $font-title;
      font-size: 48px;
      font-weight: 700;
    }

    .simpleSubheader {
      font-family: $font-default;
      font-size: 24px;
      font-weight: 600;
      align-self: stretch;
    }
  }

  .offerContainer {
    background-color: $green-50;
    border-radius: 16px;
    padding: 24px 30px 24px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 16px 16px 0px;

    p {
      margin: 0;
    }

    .offerDetailsContainer {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      justify-content: center;
      align-items: flex-start;
      gap: 6px;

      .upsellSubscriptionTermText {
        text-transform: uppercase;
        color: $type-light-primary;
        font-weight: 700;
        font-size: 16px;
      }

      .pricesContainer {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 4px;

        .upsellSubscriptionPriceText {
          color: $green-500;
          font-family: $font-default;
          font-size: 64px;
          font-weight: 600;
        }

        .currentSubscriptionPriceText {
          color: $type-light-secondary;
          font-family: $font-default;
          font-size: 32px;
          font-weight: 600;
          text-decoration: line-through;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 23px;
      right: 23px;
    }
  }

  .collarContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .collarImage {
      width: 100%;
    }
  }

  .simpleButton {
    flex: 0 1 auto;
    margin: auto 16px 48px;
  }

  .savingsText {
    font-family: $font-default;
    color: $type-light-secondary;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
