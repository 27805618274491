@import '../../styles/variables';

.main {
  composes: splitContainer from '../../styles/splitContainer.module.scss';
  margin-top: $body-top-padding;

  h2 {
    font-family: $font-title;
    margin-top: 0;
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 0em;
  }
}

.leftContainer {
  composes: container from '../../styles/container.module.scss';
  composes: stickyLeftColumn from '../../styles/splitContainer.module.scss';
}

.cartItemsContainer {
  composes: smFullWidth from '../../styles/container.module.scss';
  margin-bottom: 1em;

  .cartItems {
    border-radius: 1.5em;
    overflow: hidden;

    @media (max-width: $sm-max-width) {
      border-radius: 0;
    }
  }
}

.addItemsButtonContainer {
  composes: celledSection from '../../styles/celledSection.module.scss';
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  align-items: center;

  svg {
    margin-right: 0.5em;
  }

  @media (max-width: $sm-max-width) {
    border-radius: 0;
  }
}

.addItemsButton {
  width: 100%;
}

.multidogDiscountBanner {
  margin-top: 1.3em;
  text-align: center;
  font-size: 0.75em;
}

.multidogDiscountBannerBoldText {
  font-weight: 700;
}

@media (max-width: $md-max-width) {
  .main {
    composes: mdStacked from '../../styles/splitContainer.module.scss';
    gap: 3em;
    margin-top: 1.5em;

    h2 {
      font-size: 3rem;
    }
  }
}

.ollieWelcomeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background-color: #f5f5f2;
  padding: 2em 1.5em;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 2em;
  }

  @media (min-width: 1280px) {
    padding: 2em;
  }
}

.ollieWelcomeBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ollieWelcomeBoxImage {
  border-radius: 8px;
  height: 64px;
  width: 64px;
  aspect-ratio: 1/1;
  margin-right: 1.25em;
  object-fit: cover;

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 80px;
    width: 80px;
  }

  @media (min-width: 1280px) {
    height: 80px;
    width: 80px;
  }
}

.ollieWelcomeBoxContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ollieWelcomeBoxTitle {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 0.25em;
}

.ollieWelcomeBoxDescription {
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #3c3c3c;
}

.ollieWelcomeBoxPrice {
  align-self: flex-start;
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
}

.emptyCartContainer {
  width: 100%;
  @media (min-width: 1000px) {
    .leftContainer {
      display: none;
    }
  }
}

.emptyCartContent {
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  max-width: 389px;
  height: calc(100vh - 400px);
  gap: 24px;
}

.emptyCartIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyCartTitle {
  font-family: $font-default;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
}

.emptyCartDescription {
  font-family: $font-default;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  padding: 0 16px;

  @media (max-width: 1000px) {
    font-size: 18px;
  }
}

.emptyCartButton {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: white;
  background-color: black;
  border-radius: 100px;
  padding: 18px 32px;
}
